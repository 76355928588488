/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Rails from 'rails-ujs';
import 'select2';
import 'bootstrap';
import 'bootstrap/js/dist/tooltip';
import 'datatables.net-bs4'
import ClipboardJS from 'clipboard'
import '../components/cocoon'

require.context('../images', true);

Rails.start();

$(document).ready(function() {
    $('.select2').select2({theme: "bootstrap"});

    $('[data-toggle="tooltip"]').tooltip();

    $(function () {
        $('[data-toggle="popover"]').popover()
    });

    new ClipboardJS('.clipboard-btn');

    $('.filter-group button').on('click', function(e) {
        let btn = $(e.target);
        let filter = btn.data('filter');
        let courses = $('.available .courses .course');

        $('.filter-group button').removeClass('active');
        btn.addClass('active');

        courses.hide();

        jQuery.each(courses, function(i, courseEl) {
            let course = $(courseEl);
            let course_type = course.data('type');

            if (filter === '' || course_type === filter) {
                course.show();
            }
        });
    });

    // Fix for mobile Chrome and Safari
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    // Sidebar toogle button
    $('#sidebarCollapse').on('click', function () {
        $('#nav')[0].style.display = $('#nav')[0].style.display === 'none' ? '' : 'none';
        $('#sidebar-icon').toggleClass('fa-angle-double-right')
    });

    // DataTable common
    $('.datatable').DataTable({ "paging": false, "bInfo": false, "searching": false, 'stateSave': true,
        "stateLoaded": function (settings, data) {
            if (window.location.search == "") {
                settings.aaSorting = [0, 'desc']
            }
        }
    })

    // DataTable users page
    $('#datatable-users').DataTable(
        {
            "paging": false, "bInfo": false, "searching": false, "order": [[ 0, "desc" ]], 'stateSave': true,

            "stateLoaded": function (settings, data) {
               if (window.location.search == "") {
                    settings.aaSorting = [0, 'desc']
               }
            },

            "preDrawCallback": function( settings ) {
                let aaSorting = settings['aaSorting'][0]

                if (aaSorting.length > 1) {
                    $("#aaSorting")[0].value = aaSorting
                } else {
                    $("#aaSorting")[0].value = "0,desc"
                }

                if ($("#firstLoad")[0].value === "false") {
                    let url = new URL(window.location)
                    let query_string = url.search;
                    let search_params = new URLSearchParams(query_string);
                    search_params.set('aaSorting', aaSorting);
                    url.search = search_params.toString();
                    var new_url = url.toString();
                    window.location.replace(new_url)
                }

                $("#firstLoad")[0].value = "false"
              }
        }
    )
})